<template>
  <LayoutNew>
    <h1 class="text-center">Assets</h1>
    <MDBCard id="UploadFile">
      <!-- search bar -->
      <MDBCardHeader class="d-flex justify-content-end p-4">
        <div class="d-inline">
          <Search :searchType="'assets'" @searchResult="assetData = $event" searchLabel="Search Asset"
            @isLoading="isLoading = $event" />
        </div>
      </MDBCardHeader>
      <!-- table -->
      <MDBCardBody class="pb-0 overflow-auto">
        <MDBTable striped hover class="align-middle">
          <thead class="table-dark">
            <tr>
              <th v-for="(item, i) in tableHeaders" :key="i" scope="col">
                {{ item.header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="isLoading">
              <tr>
                <td colspan="2" class="text-center">
                  <MDBSpinner />
                </td>
              </tr>
            </template>
            <template v-else>
              <tr v-if="assetList.data.length === 0">
                <td colspan="2" class="text-center py-3">No data found.</td>
              </tr>
              <tr v-for="(item, i) in assetList.data" :key="i">
                <td>{{ item.assetId }}</td>
                <td v-if="item.title === ''"><i>(No Title Specified)</i></td>
                <td v-else>{{ item.title }}</td>
              </tr>
            </template>
          </tbody>
        </MDBTable>
        <Paginate :pageType="'assets'" @paginationData="assetList = $event">
        </Paginate>
        <MDBToast v-model="toastError" id="basic-danger-example" :delay="2000" autohide position="top-right"
          appendToBody stacking width="350px" color="danger" text="white">
          <template #title>
            {{ toastMessage }}
          </template>
        </MDBToast>
      </MDBCardBody>
    </MDBCard>
  </LayoutNew>
</template>

<script setup>
import LayoutNew from "./LayoutNew.vue";
import Search from "../../components/CSDSearch.vue";
import Paginate from "../../components/Pagination/CSDPagination.vue";
import { computed, onBeforeMount, ref, watch } from "vue";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBTable,
  MDBToast,
  MDBSpinner
} from "mdb-vue-ui-kit";
import { useTitle } from "@vueuse/core";

useTitle("Assets | CreatorShield");

const assetData = ref({
  data: [],
  pagination: {},
});

const toastError = ref(false);
const toastMessage = ref("");

dayjs.extend(customParseFormat);
const tableHeaders = computed(() => [
  {
    header: "Asset Id",
  },
  {
    header: "Title",
  },
]);

const assetList = ref({
  data: [],
  pagination: {},
});

watch(assetData, (newAssetData) => {
  assetList.value = newAssetData;
});

const isLoading = ref(false);

onBeforeMount(async () => {
  isLoading.value = true;
  await axios
    .get("api/assets/List")
    .then(function (response) {
      isLoading.value = false;
      assetList.value = response.data;
    })
    .catch(function (error) {
      isLoading.value = false;
      toastError.value = true;
      toastMessage.value = "An error occurred during request" + error;
    });
});
</script>

<style scoped>
.table-dark {
  --mdb-table-bg: var(--accent);
  border-color: var(--accent);
}
</style>
